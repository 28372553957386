// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
require("@rails/ujs").start()
// require("turbolinks").start()
require("@rails/activestorage").start()

// require("channels")
//= require modernizr
//= require jquery.validate.additional-methods
//= require jquery.validate
import 'jquery'
import 'popper.js'
import 'bootstrap'
// import modernizr from 'modernizr';

window.jQuery = $;
window.$ = $;

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)


require("channels/jquery.min")
require("channels/popper.min")
require("channels/bootstrap.min")
// require("channels/modernizr.min")
require("channels/detect")
require("channels/jquery.slimscroll")
// require("channels/custom")
require("channels/vertical-menu")
// require("channels/plugins/switchery/switchery.min")
require("channels/plugins/apexcharts/apexcharts.min")
require("channels/plugins/apexcharts/irregular-data-series")
require("channels/plugins/slick/slick.min")

require("channels/plugins/select2/select2.min")
require("channels/plugins/bootstrap-tagsinput/typeahead.bundle")
require("channels/plugins/select2/select2.min")
require("channels/plugins/bootstrap-tagsinput/bootstrap-tagsinput.min")
require("channels/plugins/multifile-master/jquery.MultiFile")
// http://lampspw.wallonie.be/dgo4/tinymvc/myfiles/plugins/multifile-2.2.1/docs.html#:~:text=MultiFile%20)%20is%20a%20non%2Dobstrusive,size%20before%20they're%20uploaded.
require("channels/custom/custom-form-select")
require("channels/moment.min")
require("channels/datetime-moment")
require("channels/custom/custom-dashboard")
require("channels/core")
require("theme/custom")
//= require summernote/summernote-bs4.min

document.addEventListener("turbolinks:load", () => {
	// console.log("turbolinks loaded ")
  // $('[data-toggle="tooltip"]').tooltip()
  // $("#datatable_ex").dataTable();
})


$(document).ready(function()
{
	// estimate_bid_job_price()
	// $.fn.dataTable.moment('DD/MM/YYYY HH:mm');

	window.setTimeout(function ()
	{
	  $(".alert_notice").fadeTo(500, 0).slideUp(500, function ()
	  {
	    $(this).remove();
	  });
	}, 5000);

	$('.dataTable').DataTable( {
    "scrollX": true,
    "aaSorting": [],
		columnDefs: [
				 { orderable: false, targets: -1 }
			]
  } );
});

// 	$('.dataTable').DataTable( {
// 		"scrollX": true,
// 		"aaSorting": [],
// 		columnDefs: [
// 				 { orderable: false, targets: -1 }
// 			],
// 		responsive: true
// 		} );
// 		$('.service_area').DataTable( {
// 	    "scrollX": true,
// 	    "aaSorting": [],
// 			columnDefs: [
// 					 { orderable: false, targets: -1 }
// 				]
// 	  } );

// 	  // $('.outside_service_area').DataTable( {
// 	  //   "scrollX": true,
// 	  //   "aaSorting": [],
// 		// 	columnDefs: [
// 		// 			 { orderable: false, targets: -1 }
// 		// 		]
// 	  // } );
// 		$('.flagged_dataTable').DataTable( {
// 			"scrollX": true,
// 			"aaSorting": [],
// 			columnDefs: [
// 					 { orderable: false, targets: -1 }
// 				]
// 			} );
// 			// $('.resolved_dataTable').DataTable( {
// 			// 	"scrollX": true,
// 			// 	"aaSorting": [],
// 			// 	columnDefs: [
// 			// 			 { orderable: false, targets: -1 }
// 			// 		]
// 			// 	} );
// 		$('.received_feedback').DataTable( {
// 			"scrollX": true,
// 			"aaSorting": [],
// 			columnDefs: [
// 				{ orderable: false, targets: -1 }
// 			]
// 		} );

// 		// $('.static_page').DataTable( {
// 		// 	"scrollX": true,
// 		// 	"aaSorting": [],
// 		// 	columnDefs: [
// 		// 		{ orderable: false, targets: -1 }
// 		// 	]
// 		// } );


//   // console.log("jQuery is working!!!")
// 	$('.nav-link').on('click', function(){
// 		// $('a[data-toggle="tab"]').on( 'shown.bs.tab', function (e) {
// 		// 	// alert(1)
// 	  //       $.fn.dataTable.tables( {visible: true, api: true} ).columns.adjust();
// 	  //   } );
// 		$('.flagged_dataTable').DataTable( {
// 			"scrollX": true,
// 			"aaSorting": [],
// 			columnDefs: [
// 				{ orderable: false, targets: -1 }
// 			]
// 		} );
// 		$('.resolved_dataTable').DataTable( {
// 			"scrollX": true,
// 			"aaSorting": [],
// 			columnDefs: [
// 				{ orderable: false, targets: -1 }
// 			]
// 		} );

// 		$('.service_area').DataTable( {
// 	    "scrollX": true,
// 	    "aaSorting": [],
// 			columnDefs: [
// 					 { orderable: false, targets: -1 }
// 				]
// 	  } );
// 	  $('.outside_service_area').DataTable( {
// 	    "scrollX": true,
// 	    "aaSorting": [],
// 			columnDefs: [
// 					 { orderable: false, targets: -1 }
// 				]
// 	  } );

// 		$('.received_feedback').DataTable( {
// 			"scrollX": true,
// 			"aaSorting": [],
// 			columnDefs: [
// 				{ orderable: false, targets: -1 }
// 			]
// 		} );
// 		$('.resolved_feedback').DataTable( {
// 			"scrollX": true,
// 			"aaSorting": [],
// 			columnDefs: [
// 				{ orderable: false, targets: -1 }
// 			]
// 		} );
// 		// $('a[data-toggle="tab"]').on("shown.bs.tab", function (e) {
// 		//   // $($.fn.DataTable.tables(true)).DataTable().fixedHeader.adjust();
// 		// 	$($.fn.dataTable.tables()).DataTable().fixedHeader.adjust();
// 		// });
// 		// $('.static_page').DataTable( {
// 		// 	"scrollX": true,
// 		// 	"aaSorting": [],
// 		// 	columnDefs: [
// 		// 		{ orderable: false, targets: -1 }
// 		// 	]
// 		// } );

// 		// $('.customer_faq').DataTable( {
// 		// 	"scrollX": true,
// 		// 	"aaSorting": [],
// 		// 	columnDefs: [
// 		// 		{ orderable: false, targets: -1 }
// 		// 	]
// 		// } );
// 		//
// 		// $('.contractor_faq').DataTable( {
// 		// 	"scrollX": true,
// 		// 	"aaSorting": [],
// 		// 	columnDefs: [
// 		// 		{ orderable: false, targets: -1 }
// 		// 	]
// 		// } );


// 	})

// 	$('#static_page').click(function(){

// 		// $('a[data-toggle="tab"]').on( 'shown.bs.tab', function (e) {
// 		// 	// alert(1)
// 	  //       $.fn.dataTable.tables( {visible: true, api: true} ).columns.adjust();
// 	  //   } );

// 		// $('.static_page').DataTable( {
// 		// 	"scrollX": true,
// 		// 	"aaSorting": [],
// 		// 	columnDefs: [
// 		// 		{ orderable: false, targets: -1 }
// 		// 	]
// 		// } );
// 	})

// 	$('#customer_faq').click(function(){
// 		// alert(2)

// 		// $('a[data-toggle="tab"]').on( 'shown.bs.tab', function (e) {
// 		// 	// alert(1)
// 	  //       $.fn.dataTable.tables( {visible: true, api: true} ).columns.adjust();
// 	  //   } );

// 		// $('.customer_faq').DataTable( {
// 		// 	"scrollX": true,
// 		// 	"aaSorting": [],
// 		// 	columnDefs: [
// 		// 		{ orderable: false, targets: -1 }
// 		// 	]
// 		// } );
// 	})

// 	$('#contractor_faq').click(function(){
// 		// alert(3)
// 		// $('a[data-toggle="tab"]').on( 'shown.bs.tab', function (e) {
// 		// 	// alert(1)
// 	  //       $.fn.dataTable.tables( {visible: true, api: true} ).columns.adjust();
// 	  //   } );

// 		// $('.contractor_faq').DataTable( {
// 		// 	"scrollX": true,
// 		// 	"aaSorting": [],
// 		// 	columnDefs: [
// 		// 		{ orderable: false, targets: -1 }
// 		// 	]
// 		// } );
// 	})
// });


// function estimate_bid_job_price(){
// 	$('.job_attribute_choice__chbx').on('change', function(){
// 		console.log(1);
// 	})
// }
