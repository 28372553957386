$(document).ready(function()
{
  estimate_bid_job_price()
  // service_area_change()
  $("#Add").click(function(event) {
    // var value = $("input[type='checkbox']").val();
    // var value = $('.service_area:checkbox:checked').val();
    // service_ids = [];
    //
    // $.each($("input[name='service_area']:checked"), function(K, V) {
    //     service_ids.push(V.value);
    // });
    // add_service_area(service_ids, this, event)
    add_service_area()

  })
  assign_job_addon_ids()
  assign_job_attr_choices()
})

$(document).ready(function()
{
  $(".addAddon").click(function(event) {
      total = $("#addon_total").val();
      add_addon(total)
  })
})
// function add_service_area(ids, abcd, ev)
function add_service_area()
{
  // if (ids.length === 0)
  // {
  //
  // }
  // else
  // {
    // var selectedDeliveryType = $(".delivery option:selected").val();
    // $.ajax({
    //   type: "get",
    //   url: "/admin/get_service_area?selected_ids=" + $('#selected_service_areas').val() + "&job_type_id=" + $('#job_type_id').val() + "&removed_ids=" + $('#removed_service_areas').val(),
    //   success: function(data) {
    //     console.log('-------div-------', $("#TextBoxDiv"));
    //       $(".service_areas").prepend(data.data);
    //       $("#myModal1").modal("hide");
    //       if($('#removed_service_areas').val()){
    //         $('#removed_service_areas').split(',').each(function(i){
    //           console.log('-----i----', i);
    //           $('#TextBoxDiv'+i)
    //         })
    //       }
    //   },
    $.ajax({
      type: "get",
      url: "/admin/get_service_area?ids=" + $('#selected_service_areas').val() + "&job_type_id="+$('#job_type_id').val(),
      success: function(data) {
        $(".service_areas").prepend(data.data);
        $("#myModal1").modal("hide");
      },

      error: function(jqXHR, textStatus, errorThrown) {
        // console.log("Errorrr!!!!")
      }
    })
  // }
}

  function add_addon(id)
  {
    if (id.length === 0) {
      } else {
          // var selectedDeliveryType = $(".delivery option:selected").val();
          $.ajax({
              type: "get",
              url: "/admin/add_addon/" + id,
              success: function(data) {
                  // $(".new_added").html(data.data);
                  $( data.data ).insertAfter( $( ".add_on_module" ) );
                  $("#addon_total").val(parseInt(id) + 1);
              },
              error: function(jqXHR, textStatus, errorThrown) {
                // console.log("Errorrr!!!!")
              }
          })
      }
  }

  $(document).ready(function()
  {
    $("#replace_image").click(function() {
        $('.image_1').remove();
        $(this).remove();
        $('.show_me').show();
    })
  })
  $(document).ready(function()
  {
    $("#replace_image2").click(function() {
        $('.image_2').remove();
        $(this).remove();
        $('.show_me2').show();
    })
  })
  $(document).ready(function() {
    $("select.delivery").change(function()
    {
        // var selectedDeliveryType = $(".delivery option:selected").val();

        var selectedDeliveryType = $(".delivery").select2("val");

        $(".hide_me_dd").remove();
        $('.show_me_dd').show();
        // var selectedDeliveryType = $(".delivery option:selected").text();
        $("#job_type").html('');
        $.ajax({
            type: "get",
            url: "/admin/get_job_type",
            data: {
                delivery_type: selectedDeliveryType
            },
            success: function(data) {
                $("#job_type").html(data.data);

                $("#print_1").html('');
                $("#print_2").html('');
                $("#print_3").html('');
                $("#print_4").html('');
                $("#print_5").html('');
                $("#print_6").html('');
                $("#print_7").html('');
                $("#print_8").html('');
                $("#print_9").html('');
                $("#print_10").html('');
            },
            done: function(data) {
            },
            error: function(jqXHR, textStatus, errorThrown) {
                $("#job_type").html(jqXHR);
                $("#print_1").html('');
                $("#print_2").html('');
                $("#print_3").html('');
                $("#print_4").html('');
                $("#print_5").html('');
                $("#print_6").html('');
                $("#print_7").html('');
                $("#print_8").html('');
                $("#print_9").html('');
                $("#print_10").html('');
            }
        })
    });

    if ($('select.job_type').val()) {
        // var selectedJobType = $(".job_type option:selected").val();
        // var selectedDelive ryType = $(".delivery option:selected").val();
        // var selectedJobType = $(".job_type option:selected").text();
        // var selectedDeliveryType = $(".delivery option:selected").text();

        var selectedDeliveryType = $(".delivery").select2("val");
        var selectedJobType = $('select.job_type').select2("val");
        var job_type_id = $(".params_id").val();
        $.ajax({
            type: "get",
            url: "/admin/add_fields",
            data: {
                job_type: selectedJobType,
                delivery_type: selectedDeliveryType,
                id: job_type_id
            },
            success: function(data) {
                $("#print_1").html(data.data[0]);
                $("#print_2").html(data.data[1]);
                $("#print_3").html(data.data[2]);
                $("#print_4").html(data.data[3]);
                $("#print_5").html(data.data[4]);
                $("#print_6").html(data.data[5]);
                $("#print_7").html(data.data[6]);
                $("#print_8").html(data.data[7]);
                $("#print_9").html(data.data[8]);
                $("#print_10").html(data.data[9]);
                add_value(); // this for Dynamic value add

            },
            done: function(data) {
            },
            error: function(jqXHR, textStatus, errorThrown) {
                $("#job_type").html(jqXHR);
            }
        })
    } else {
    }

    $("select.job_type").change(function() {
        var selectedJobType = $(".job_type option:selected").val();

        // var selectedDeliveryType = $(".delivery option:selected").val();

        var selectedDeliveryType = $(".delivery").select2("val");
        var selectedJobType = $(".job_type").select2("val");
        var job_type_id = $(".params_id").val();
        // var selectedJobType = $(".job_type option:selected").text();
        // var selectedDeliveryType = $(".delivery option:selected").text();


        $.ajax({
            type: "get",
            url: "/admin/add_fields",
            data: {
                job_type: selectedJobType,
                delivery_type: selectedDeliveryType,
                id: job_type_id
            },
            success: function(data) {
                $("#print_1").html(data.data[0]);
                $("#print_2").html(data.data[1]);
                $("#print_3").html(data.data[2]);
                $("#print_4").html(data.data[3]);
                $("#print_5").html(data.data[4]);
                $("#print_6").html(data.data[5]);
                $("#print_7").html(data.data[6]);
                $("#print_8").html(data.data[7]);
                $("#print_9").html(data.data[8]);
                $("#print_10").html(data.data[9]);
                add_value();
            },
            done: function(data) {
            },
            error: function(jqXHR, textStatus, errorThrown) {
                $("#job_type").html(jqXHR);
            }
        })
    });

});
function runme(selected)
{
  $(".modal-body #username").val(selected);
}

$(document).ready(function() {
  $("select.cust_name").change(function()
  {
    // alert('change1')
    $('.show-suggested').hide()
    $('.bid-price-scroller').hide()
    $('.bid_amt_block').hide()
    $('.get_estimated_price').attr('disabled', true)
    $('.get_estimated_price').css('cursor', 'not-allowed')
    // var selectedDeliveryType = $(".delivery option:selected").val();
    var selectedDeliveryType = $("select.cust_name").val();
    var job_id = $("#job_id").val();
    $.ajax({
        type: "get",
        url: "/admin/get_customer_details",
        data: {
            id: selectedDeliveryType,
            job_id: job_id
        },
        success: function(data) {
          $('#remove_me').remove();
            $("#print_mobile_number").val(data.data.customer.mobile_no);
            $("#print_email_id").val(data.data.customer.email);
            $("#add_dropd_down").html(data.data.properties);
            $("#add_card_detail").html(data.data.card_details);
        },
        done: function(data) {
            console.log("Doneeee!!!!")
        },
        error: function(jqXHR, textStatus, errorThrown) {
            console.log("Errorrr!!!!")
        }
      })
  });
});

$(document).ready(function()
{
  $(function ()
  {
    $("select.payment_mode_on_change").change(function()
      {
        var skillsSelect = document.getElementById("job_payment_mode");
        var text_ = skillsSelect.options[skillsSelect.selectedIndex].text;
        var this_text = 'Online';
        var this_text_1 = 'online';
        var name = 'd-none';
        thiz = $(".add_online_cards")
        if(text_ === this_text)
        {
          thiz.removeClass(name);
        }
        else if(text_ === this_text_1)
        {
          thiz.removeClass(name);
        }
        else
        {
          thiz.addClass(name);
        }
      });
  });
});

$(document).ready(function()
{
  $(function ()
  {
    if ($('select.payment_mode_on_change').val())
    {
      var skillsSelect = document.getElementById("job_payment_mode");
      var text_ = skillsSelect.options[skillsSelect.selectedIndex].text;
      var this_text = 'Online';
      var this_text_1 = 'online';
      var name = 'd-none';
      thiz = $(".add_online_cards")
      if(text_ === this_text)
      {
        thiz.removeClass(name);
      }
      else if(text_ === this_text_1)
      {
        thiz.removeClass(name);
      }
      else
      {
        thiz.addClass(name);
      }
    }
    else{}
  });
});

$(document).ready(function() {
  // alert(1)
  if ($('select.cust_name').val())
  {
    // alert('change2')
    // alert(2)
    // var selected_customer = $('select.cust_name').select2("val");
    var selected_customer = $('select.cust_name').val()
    var job_id = $("#job_id").val();
    $.ajax({
        type: "get",
        url: "/admin/get_customer_details",
        data: {
            id: selected_customer,
            job_id: job_id
        },
        success: function(data) {
          $('#remove_me').remove();
          $("#print_mobile_number").val(data.data.customer.mobile_no);
          $("#print_email_id").val(data.data.customer.email);
          $("#add_dropd_down").html(data.data.properties);
          $("#add_card_detail").html(data.data.card_details);
          updat_value_job();
        },
        done: function(data) {
            // console.log("Doneeee!!!!")
        },
        error: function(jqXHR, textStatus, errorThrown) {
            // console.log("Errorrr!!!!")
        }
      })
  }
  else
  {
  }
});

$(document).on('change', '.property_dropdown', function(){
  $('.show-suggested').hide()
  $('.bid-price-scroller').hide()
  $('.bid_amt_block').hide()
  $('.get_estimated_price').attr('disabled', true)
  $('.get_estimated_price').css('cursor', 'not-allowed')
  var property_id = $(".property_dropdown option:selected").val();
  $.ajax({
      type: "get",
      url: "/admin/job/job_types",
      data: {
          property_id: property_id
      },
      success: function(data) {
        $("#job_job_type_id").html(data.data);
        $("#job_property_udid").val($(".property_dropdown option:selected").data('udid'));
        $(".add_here_job_attr").html('');
        $(".add_here_job_addon").html('');
        $(".add_here_job_attr_fieldset").hide();
        $(".add_here_job_addon_html").hide();
        // assign_job_addon_ids();
        // assign_job_attr_choices()
        // estimate_bid_job_price();
      },
      done: function(data)
      {
      },
      error: function(jqXHR, textStatus, errorThrown)
      {
      }
    })
});

$(document).on('change', '.job_type_create_job', function(){
  $('.show-suggested').hide()
  $('.bid-price-scroller').hide()
  $('.bid_amt_block').hide()
  $('.get_estimated_price').attr('disabled', true)
  $('.get_estimated_price').css('cursor', 'not-allowed')
  var job_type_id = $(".job_type_create_job option:selected").val();
  var job_type_text = $(".job_type_create_job option:selected").text();
  var job_id = $("#job_id").val();
  $.ajax({
      type: "get",
      url: "/admin/job/get_job_attributes",
      data: {
          job_type_id: job_type_id,
          job_id: job_id,
          property_id: $('#job_property_id').val()
      },
      success: function(data) {
        $("#assign_contractor").html(data.contractors);

        if (!$.trim(data.response[0]))
        {
          // blank record
          $(".add_here_job_attr_fieldset").hide();
        }
        else
        {
          $(".add_here_job_attr").html(data.response[0]);
          $(".add_here_job_attr_fieldset").show();
        }
        if (!$.trim(data.response[1]))
        {
          // blank record
          $(".add_here_job_addon_html").hide();
        }
        else
        {
          $(".add_here_job_addon").html(data.response[1]);
          $(".add_here_job_addon_html").show();
        }
        $("#job_job_type_udid").val($(".job_type_create_job option:selected").data('udid'));
        $('.get_estimated_price').show()
        print_comm = " ("+job_type_text+": "+data.commission_rate+"%) "
        $("#recurrance_duration_days").val(data.recurrance_duration_days);
        $("#cadence_selection").html(data.cadence_dd);

        jQuery('#job_recurring_info_start_date').attr(
        {
          "max": data.def_days,
          "min": data.today
        });
        // jQuery('#job_recurring_info_end_date').attr(
        // {
        //   "max": data.def_days,
        // });

        $("#job_type_commission_rate").html(print_comm);

        assign_job_addon_ids();
        assign_job_attr_choices()
        estimate_bid_job_price();
      },
      error: function(jqXHR, textStatus, errorThrown) {
        $(".add_here_job_attr").html('');
        $(".add_here_job_addon").html('');

      }
    })
});

function add_value()
{
    var low_x_time = $(".low_x_time").val();
    var max_x_time = $(".max_x_time").val();
    var min_hours = $(".min_hours").val();
    var max_hours = $(".max_hours").val();
    var hourly_price = $(".hourly_price").val();
    var price = $(".price").val();
    var contr_accept_other_job = $(".contr_accept_other_job").val();
    var bid_to_recu_def_job_cade_days = $(".bid_to_recu_def_job_cade_days").val();
    var bid_to_recu_min_job_cade_days = $(".bid_to_recu_min_job_cade_days").val();
    var bid_to_recu_max_job_cade_days = $(".bid_to_recu_max_job_cade_days").val();
    var bid_to_recu = $(".bid_to_recu").val();

    $("#job_type_low_x_time").val(low_x_time);
    $("#job_type_max_x_time").val(max_x_time);

    $("#job_type_min_hours").val(min_hours);
    $("#job_type_max_hours").val(max_hours);
    $("#job_type_hourly_price").val(hourly_price);
    $("#job_type_price").val(price);
    $("#job_type_contr_accept_other_job").val(contr_accept_other_job);

    $("#job_type_bid_to_recu_def_job_cade_days").val(bid_to_recu_def_job_cade_days);
    $("#job_type_bid_to_recu_min_job_cade_days").val(bid_to_recu_min_job_cade_days);
    $("#job_type_bid_to_recu_max_job_cade_days").val(bid_to_recu_max_job_cade_days);
    // $("#job_type_bid_to_recu").prop('checked', bid_to_recu);
    if(bid_to_recu == 'true')
    {
      $("#job_type_bid_to_recu"). prop("checked", true);
    }
    else
    {
      $("#job_type_bid_to_recu"). prop("checked", false);
    }

}

function updat_value_job()
{
  $(".property_dropdown").val($("#property_id_edit").val());
  if ($('select.property_dropdown').val())
  {
    var property_id = $(".property_dropdown option:selected").val();
    $.ajax({
        type: "get",
        url: "/admin/job/job_types",
        data: {
            property_id: property_id
        },
        success: function(data) {
          $("#job_job_type_id").html(data.data);
          $("#job_property_udid").val($(".property_dropdown option:selected").data('udid'));
          $(".job_type_create_job").val($("#job_type_edit_id").val());
          $(".delivery_type_on_change_job").val($("#delivery_type_edit_id").val());
          // $(".delivery_type_on_change_job").val($("#delivery_type_edit_id").val());
          $("#job_payment_method").val($("#payment_modes_udid").val());
          update_job_attribute_add_on()
          update_recurring_dropdown();
        },
        done: function(data)
        {
        },
        error: function(jqXHR, textStatus, errorThrown)
        {
        }
      })
  }
  else
  {
    $(".delivery_type_on_change_job").val($("#delivery_type_edit_id").val());
  }

}
function update_recurring_dropdown()
{
  var text = $(".delivery_type_on_change_job option:selected").text();
  var num = $("#delivery_type_edit_id").val();
  var this_text = 'Recurring';
  var num_text = '2';
  if (text == this_text || num == num_text )
  {
    $('.cadence_div').show();
  }
  else
  {
    $('.cadence_div').hide();
  }
}
function update_job_attribute_add_on()
{
  if ($('select.job_type_create_job').val())
  {
    var job_type_id = $(".job_type_create_job option:selected").val();
    var job_type_text = $(".job_type_create_job option:selected").text();
    var job_id = $("#job_id").val();
    $.ajax({
      type: "get",
      url: "/admin/job/get_job_attributes",
      data: {
          job_type_id: job_type_id,
          job_id: job_id,
          property_id: $('#job_property_id').val()
      },
      success: function(data)
      {
        $("#assign_contractor").html(data.contractors);

        if (!$.trim(data.response[0]))
        {
          // blank record
          $(".add_here_job_attr_fieldset").hide();
        }
        else
        {
          $(".add_here_job_attr").html(data.response[0]);
          $(".add_here_job_attr_fieldset").show();
        }
        if (!$.trim(data.response[1]))
        {
          // blank record
          $(".add_here_job_addon_html").hide();
        }
        else
        {
          $(".add_here_job_addon").html(data.response[1]);
          $(".add_here_job_addon_html").show();
        }
        // here come the selected checkbox check code
        job_attribute_select_checkbox()
        $("#job_job_type_udid").val($(".job_type_create_job option:selected").data('udid'));
        print_comm = " ("+job_type_text+" commission : "+data.commission_rate+" %) "
        $("#recurrance_duration_days").val(data.recurrance_duration_days);
        $("#cadence_selection").html(data.cadence_dd);
        // $('#cadence_selection option:eq(4)').prop('selected', true)
        job_recurring_cadence = $("#job_recurring_cadence").val()
        console.log("-=------------------",job_recurring_cadence);
        $("#cadence_selection option[value='"+job_recurring_cadence+"']").prop('selected', true);

        jQuery('#job_recurring_info_start_date').attr(
        {
          "max": data.def_days,
          "min": data.today
        });
        // jQuery('#job_recurring_info_end_date').attr(
        // {
        //   "max": data.def_days,
        // });
        $("#job_type_commission_rate").html(print_comm);
        job_add_on_select_checkbox()
      },
      error: function(jqXHR, textStatus, errorThrown) {
        $(".add_here_job_attr").html('');
        $(".add_here_job_addon").html('');
      }
    })
  }
  else
  {
  }
}

function job_attribute_select_checkbox()
{
  var udid_comma_separated = $("#job_attribute_choices_udid_arr").val();
  var udid_arr = udid_comma_separated.split(',');
  if(udid_comma_separated.length > 0)
  {
    $.each(udid_arr, function( index, value )
    {
      value = $.trim(value);
      value = value.replace(/\"/g, "")
      id = "#".concat(value)
      $($.trim(id)).prop('checked', true);
    });
  }
}
function job_add_on_select_checkbox()
{
  var udid_comma_separated = $("#job_attribute_addon_arr").val();
  if(!udid_comma_separated == '')
  {
    var udid_arr = udid_comma_separated.split(',');
    $.each(udid_arr, function( index, value )
    {
      value = $.trim(value);
      value = value.replace(/\"/g, "")
      $("#"+value).prop('checked', true);
    });
  }

  var job_attribute_addon_arr_with_quantity = $("#job_attribute_addon_arr_with_quantity").val();
  if(!job_attribute_addon_arr_with_quantity == '')
  {
    job_attribute_addon_arr_with_quantity = job_attribute_addon_arr_with_quantity.replace('[','');
    job_attribute_addon_arr_with_quantity = job_attribute_addon_arr_with_quantity.replace(']','');
    var trainindIdArray = job_attribute_addon_arr_with_quantity.split(',');

    var odd_arr = [];
    var even_arr = [];

    $.each(trainindIdArray, function( index, value )
    {
      value = value.split("\"").join("").trim();
      if(index % 2 == 0)
      {
        even_arr.push(value)
      }
      else
      {
        odd_arr.push(value)
      }
    });
    var merge_arr = {};
    var i;
    for (i = 0; i < odd_arr.length; i++)
    {
      merge_arr[even_arr[i]] = odd_arr[i];
    }
    for (var key in merge_arr) {
      var id = "#"+key+"_quantity";
      id = id.split("\"").join("").trim();
      $(id).val(parseInt(merge_arr[key]));
    }

  }
}
//

// $(document).ready(function() {
//     $("#Add").click(function(event) {
//         // var value = $("input[type='checkbox']").val();
//         // var value = $('.service_area:checkbox:checked').val();
//         service_ids = [];

//         $.each($("input[name='service_area']:checked"), function(K, V) {
//             service_ids.push(V.value);
//         });
//         console.log(service_ids);
//         add_service_area(service_ids, this, event)
//     })
// })

// function add_service_area(ids, abcd, ev) {

//     if (ids.length === 0) {

//     } else {
//         // var selectedDeliveryType = $(".delivery option:selected").val();
//         $.ajax({
//             type: "get",
//             url: "/admin/get_service_area/" + ids,
//             success: function(data) {
//                 $("#TextBoxDiv1").html(data.data);
//                 // $("#myModal1").modal("hide");
//                 $('#myModal1').hide();
//                 $(".modal-backdrop").hide();
//                 console.log(ev);
//                 console.log("here i amd");
//                 ev.preventDefault();

//             },
//             error: function(jqXHR, textStatus, errorThrown) {
//                 // console.log("Errorrr!!!!")
//             }
//         })
//     }
// }

function remove_this(class_id)
{
  $('.' + class_id).remove();
}

$(function()
{
  $('.pop').on('click', function() {
    $('.imagepreview').attr('src', $(this).find('img').attr('src'));
    $('#imagemodal').modal('show');
  });
});
function readURL(input)
{
  if (input.files && input.files[0])
  {
    var reader = new FileReader();
    reader.onload = function (e)
    {
      $(input).next('img').attr('src', e.target.result);
    }
    reader.readAsDataURL(input.files[0]);
  }
}

function set_rec_val()
{
  $("#job_type_bid_to_recu_def_job_cade_days").val($(".bid_to_recu_def_job_cade_days").val());
  $("#job_type_bid_to_recu_min_job_cade_days").val($(".bid_to_recu_min_job_cade_days").val());
  $("#job_type_bid_to_recu_max_job_cade_days").val($(".bid_to_recu_max_job_cade_days").val());
}

$(document).ready(function(){
  $("div").on("click", "#job_type_bid_to_recu", function()
  {
    if($(this).prop("checked") == true)
    {
      data_6 = `<label for="job_type_bid_to_recu_min_job_cade_days">Min. Job cadence(Days)</label><input class="form-control" placeholder="Min. Job cadence" type="number" name="job_type[bid_to_recu_min_job_cade_days]" id="job_type_bid_to_recu_min_job_cade_days" value="" title="">`;

      data_7 = `<label for="job_type_bid_to_recu_max_job_cade_days">Max. Job cadence(Days)</label><input class="form-control" placeholder="Max. Job cadence" type="number" name="job_type[bid_to_recu_max_job_cade_days]" id="job_type_bid_to_recu_max_job_cade_days" value="" title="">`

      data_8 = `<label for="job_type_bid_to_recu_def_job_cade_days">Def. Job cadence(Days)</label><input class="form-control" placeholder="Def. Job cadence" type="number" name="job_type[bid_to_recu_def_job_cade_days]" id="job_type_bid_to_recu_def_job_cade_days" value="" title="">`

      $("#print_6").html(data_6);
      $("#print_7").html(data_7);
      $("#print_8").html(data_8);
      set_rec_val();
    }
    else if($(this).prop("checked") == false)
    {
      $("#print_6").html('');
      $("#print_7").html('');
      $("#print_8").html('')
    }
  });
});



$(document).ready(function() {

    $('.show_me_recurring_page').show();
    $('.hide_me_recurring_page').hide();

    $(".btn_recurring_update_click").click(function()
    {
      // var value = $('.job_type_check_box:checkbox:checked');

      var favorite = [];
      $.each($('.job_type_check_box:checkbox:checked'), function(){
          favorite.push($(this).val());
      });
      selected_checkbox = favorite.join(",")
      $.ajax({
        type: "get",
        url: "/admin/get_recurring_job_list/" + selected_checkbox,
        success: function(data)
        {
          if (!$.trim(data.data))
          {
            $('.show_me_recurring_page').show();
            $('.hide_me_recurring_page').hide();
            // alert("What follows is blank: " + data);
          }
          else
          {
            // alert("What follows is not blank: " + data);
            $('.show_me_recurring_page').hide();
            $('.hide_me_recurring_page').show();
          }
          $(".tbody_data").html(data.data);
          hidden_date_time_update_run(); // to run the date time updation .

          if ($.fn.DataTable.isDataTable('.datatableNEW'))
          {
            $('.datatableNEW').DataTable().destroy();
          }
          // $('#dataTable tbody').empty();

          $('.datatableNEW').DataTable( {
            "scrollX": false,
            "aaSorting": [],
            "lengthMenu":   [[25, 50, 100], [25, 50, 100]],
            columnDefs: [
                { orderable: false, targets: 0 }
              ]
          } );
        },
        error: function(jqXHR, textStatus, errorThrown) {
          // console.log("Errorrr!!!!")
        }
      })
    });
});

$(function () {
  $('.unchecked_class').hide();
    $('#selectallJobs').change(
    function()
    {
      var checked = $(this).is(':checked'); // Checkbox state
      // Select all
      if(checked)
      {
        $('.dispatch_list_chkbx').prop('checked', true);
        $('.unchecked_class').show();
        $('.checked_class').hide();
        $(".dispatch_selected_job").show();
      }
      else
      {
        $('.dispatch_list_chkbx').prop('checked', false);
        $('.checked_class').show();
        $('.unchecked_class').hide();
        $(".dispatch_selected_job").hide();
      }
    });
});


$(document).ready(function() {
  var prog = 'input[name="dispatch_list_chkbx"]';
  var $submit = $(".dispatch_selected_job").hide();
  $(document).on("change", prog, function() {
    // console.log($(prog).is(":checked"))
    $submit.toggle($(prog).is(":checked"));
  });
});

$(document).ready(function() {
  var checkboxes = $("input[name='job_type_check_box_name']"),
    submitButt = $(".btn_recurring_update_click");

  checkboxes.click(function() {
      submitButt.attr("disabled", !checkboxes.is(":checked"));
  });
});
$( document ).ajaxStart(function() {
   $("#overlay").fadeIn(300);
});
$( document ).ajaxStop(function() {
  setTimeout(function(){
   $("#overlay").fadeOut(300);
 }, 500	)
});

$(document).ready(function() {
  // $(".dispatch_selected_job").attr('disabled', true)
  $(".dispatch_selected_job").click(function()
  {
    $(".dispatch_selected_job").addClass('disabled')
    $(".dispatch_selected_job").css('cursor', 'not-allowed')
    // var value = $('.job_type_check_box:checkbox:checked');
    var selected_job_id = [];
    let bid_and_comission = {};
    $.each($('.dispatch_list_chkbx:checkbox:checked'), function(){
      var job_id = $(this).val();
      var bid_amnt = $("#bid_amnt_" + job_id).val()
      var comission_amnt = $("#msp_commision_amnt_" + job_id).val()
      bid_and_comission[job_id] = [bid_amnt, comission_amnt]
        selected_job_id.push($(this).val());
    });
    selected_checkbox = selected_job_id.join(",")
    $.ajax({
      type: "get",
      url: "/admin/create_recursiv_jobs/" + selected_checkbox,
      data: {
        bid_and_comission: bid_and_comission
      },
      success: function(data)
      {
        if (!$.trim(data.data))
        {
          $('.show_me_recurring_page').show();
          $('.hide_me_recurring_page').hide();
          // alert("What follows is blank: " + data);
          location.reload(true);
        }
        else
        {
          // alert("What follows is not blank: " + data);
          $('.show_me_recurring_page').hide();
          $('.hide_me_recurring_page').show();
        }
        $(".tbody_data").html(data.data);
      },
      error: function(jqXHR, textStatus, errorThrown) {
        // console.log("Errorrr!!!!")
      }
    })
  });
});

$(document).ready(function() {
  $(".dispatch_all_job").click(function()
    {
       var tt= $(this)
      // var value = $('.job_type_check_box:checkbox:checked');
        var selected_job_id = [];
        let bid_and_comission = {};
        $.each($('.dispatch_list_chkbx:checkbox'), function(){
          var job_id = $(this).val();
          var bid_amnt = $("#bid_amnt_" + job_id).val()
          var comission_amnt = $("#msp_commision_amnt_" + job_id).val()
          bid_and_comission[job_id] = [bid_amnt, comission_amnt]
            selected_job_id.push($(this).val());
        });
        selected_checkbox = selected_job_id.join(",")
        // console.log("===========selected_checkbox :  ",selected_checkbox);
        if(selected_job_id.length > 0 ) {
          sts = confirm("This action will dispatch "+selected_job_id.length+" jobs, are you sure you want to dispatch jobs?");
          if (sts == true || sts == 'true')
          {
            tt.attr('disabled', true)
            $.ajax({
              type: "get",
              url: "/admin/create_recursiv_jobs/" + selected_checkbox,
              data: {
                bid_and_comission: bid_and_comission
              },        
              success: function(data)
              {
                tt.attr('disabled', false)
                if (!$.trim(data.data))
                {
                  $('.show_me_recurring_page').show();
                  $('.hide_me_recurring_page').hide();
                  // alert("What follows is blank: " + data);
                  location.reload(true);
                }
                else
                {
                  // alert("What follows is not blank: " + data);
                  $('.show_me_recurring_page').hide();
                  $('.hide_me_recurring_page').show();
                }
                $(".tbody_data").html(data.data);
              },
              error: function(jqXHR, textStatus, errorThrown) {
                // console.log("Errorrr!!!!")
              }
            })
          }
        }
    });
});

$(function ()
{
  $('.selected_chbx_job_type').change(
    function()
    {
      var checked = $(this).is(':checked'); // Checkbox state
      id = ".experience_show_" + $(this).data('value');
      id_pass = "#job_type_id_" + $(this).data('value');
      // Select all
      if(checked)
      {
        // console.log($(this).val());
        $(id_pass).val($(this).val());
        $(id).show();

        show_data_to = ".experience_show_" + $(this).data('value')
        // console.log(show_data_to);
        $(show_data_to).html(`
        <div class="form-group remove_`+$(this).data('value')+`">
          <input class="small_input" type="number" name="contractor[job_type][`+$(this).data('value')+`][exp_year]" placeholder="Experience in Years" min="0" required="true">
          <input class="small_input" type="number" name="contractor[job_type][`+$(this).data('value')+`][exp_month]" placeholder="Experience in Months" min="0">
          <input id="job_type_id_<%= chbx_id %>" type="hidden" name="contractor[job_type][`+$(this).data('value')+`][job_type_id]" value="`+$(this).val()+`" >
          <label for="contractor[job_type][`+$(this).data('value')+`][exp_year]" class="error"></label>
        </div>`);

      }
      else
      {
        show_data_to = ".remove_" + $(this).data('value')
        $( show_data_to ).remove();
        // $(show_data_to).html('');
        $(id).hide();
      }
    });
});
$(document).ready(function()
{
  if ($('.contractor_exp_add').is(':checked'))
  {
    service_ids = [];
    $.each($(".contractor_exp_add:checked"), function(K, V) {
      // console.log("V.value :",V.value);
      service_ids.push(V.value);

      get_id = "#contractor_job_types_"+V.value
      array_index_id = $(get_id).data('value');

      show_data_to = ".experience_show_" + array_index_id
      exp_year_value = $("#"+V.value).data('exp_year');
      exp_month_value = $("#"+V.value).data('exp_month');
      $(show_data_to).show();
      $(show_data_to).html(`
      <div class="form-group remove_`+array_index_id+`">
      <input class="small_input" type="number" name="contractor[job_type][`+array_index_id+`][exp_year]" title="Experience in Years" placeholder="Experience in Years" value="`+exp_year_value+`" min="0" required="true">
      <input class="small_input" type="number" name="contractor[job_type][`+array_index_id+`][exp_month]" title="Experience in Months" placeholder="Experience in Months" value="`+exp_month_value+`" min="0">
      <input id="job_type_id_`+V.value+`" type="hidden" name="contractor[job_type][`+array_index_id+`][job_type_id]" value="`+V.value+`" >
      <label for="contractor[job_type][`+$(this).data('value')+`][exp_year]" class="error"></label>
      </div>`);

    });

    // id = ".experience_show_" + $(this).data('value');
    // id_pass = "#job_type_id_" + $(this).data('value');
    // console.log("THISZ :",$(this));
    // console.log($(this).val());
    // $(id_pass).val($(this).val());
    // $(id).show();

    // show_data_to = ".experience_show_" + $(this).data('value')
    // console.log($(this).val());
    // $(show_data_to).html(`
    // <div class="form-group remove_`+$(this).data('value')+`">
    // <input class="small_input" type="number" name="contractor[job_type][`+$(this).data('value')+`][exp_year]" title="Experience in Years" min="0">
    // <input class="small_input" type="number" name="contractor[job_type][`+$(this).data('value')+`][exp_month]" title="Experience in Months" min="0">
    // <input id="job_type_id_<%= chbx_id %>" type="hidden" name="contractor[job_type][`+$(this).data('value')+`][job_type_id]" value="`+$(this).val()+`" >
    // </div>`);

  } else {
  }
});
$(document).ready(function()
{
  $(function ()
  {
    $('.delivery_type_on_change_job').change(
      function()
      {
        var text = $(".delivery_type_on_change_job option:selected").text();
        var this_text = 'Recurring';
        $('#estimate_btn_click').val(false)
        if (text == this_text)
        {
          $('.cadence_div').show();
          $('.bid_amt_block').hide()
          $('.variable_price_block').show();
          $('.send_to_queue_block').show();
          $('.quoted_price_block').hide();
          $('.schedule_dates_div').hide();
          $('.hourly_price_block').hide()
          $('#is_hourly_or_quoted').val(false)
        }
        else
        {
          $('.cadence_div').hide();
          if (text == 'Scheduled')
          {
            $('.hourly_price_block').hide()
            $('.variable_price_block').hide();
            $('.send_to_queue_block').hide();
            $('.quoted_price_block').show();
            $('.schedule_dates_div').show();
            $('#is_hourly_or_quoted').val(true)
            $('#quoted_price').attr('required', true)
            $('#quoted_price').attr('min', 1)
            $('#quoted_price').attr('max', 9999)
            $('.create_job_btn').attr('disabled', false)
            $('.create_job_btn').css('cursor', 'pointer')
          }
          else
          {
            check_service_type()
            // var service_type;
            console.log("------check_service_type---------")
              console.log($('#delivery_job_service_type').val())
            setTimeout(function(){
              if($('#delivery_job_service_type').val() == 3){
                // alert('its2')
                $('.hourly_price_block').show()
                if($('#job_hourly_price').val() == ''){
                  $('#job_hourly_price').val($('#job_hourly_price').attr('min'))
                }
                // $('#job_hourly_price').val($('#job_hourly_price').attr('min'))
                // $('#job_hourly_price').attr('min', 1)
                $('#is_hourly_or_quoted').val(true)
                $('.variable_price_block').hide();
                $('.send_to_queue_block').show();
                $('.quoted_price_block').hide();
                $('.schedule_dates_div').hide();
              }
              else{
                // alert('not there')
                $('#is_hourly_or_quoted').val(false)
                $('.hourly_price_block').hide()
                $('.bid_amt_block').hide()
                $('.variable_price_block').show();
                $('.send_to_queue_block').show();
                $('.quoted_price_block').hide();
                $('.schedule_dates_div').hide();
              }
              // $('.create_job_btn').attr('disabled', false)

            }, 1000)
            // console.log();
            $('.create_job_btn').attr('disabled', false)
            $('.create_job_btn').css('cursor', 'pointer')
          }
        }
      });
  });
});


// *********************reset password email code start ****************************

jQuery(document).ready(function(){
    jQuery(".edit_customer").validate({
      rules:{
        "customer[password]": {
          required: true,
          minlength: 8,
          maxlength: 15,
          pwcheck:true,
        },
        "customer[password_confirmation]": {
          required: true,
          maxlength: 15,
          equalTo: "#customer_password"
        },
      },

      messages: {
        "customer[password]": {
          required: "New password cannot be blank",
          minlength: "Password must contains 8+ characters and at least one number.",
          maxlength: "Maximum length should be 15 characters."
        },
        "customer[password_confirmation]": {
          required: "Confirm password cannot be blank",
          equalTo: "Password & Confirm password must be same",
          maxlength: "Maximum length should be 15 characters."
        },
      }
      // submitHandler: function (form){
      //   $(form).submit();
      // }
    });

    jQuery(".edit_contractor").validate({
      rules:{
        "contractor[password]": {
          required: true,
          minlength: 8,
          maxlength: 15,
          contrpwcheck:true,
        },
        "contractor[password_confirmation]": {
          required: true,
          maxlength: 15,
          equalTo: "#contractor_password"
        },
      },

      messages: {
        "contractor[password]": {
          required: "New password cannot be blank",
          minlength: "New password should contains min. 8 characters, one digit, Special characters, Upper case & lower case letter.",
          maxlength: "Maximum length should be 15 characters."
        },
        "contractor[password_confirmation]": {
          required: "Confirm password cannot be blank",
          equalTo: "Password & Confirm password must be same",
          maxlength: "Maximum length should be 15 characters."
        },
      }

      // submitHandler: function (form){
      //   $(form).submit();
      // }
    });

    jQuery.validator.addMethod("pwcheck", function(password, element) {
    return password.match('^(?=.*[a-zA-Z])(?=.*[0-9]).{8,15}$');
    }, "Password must contains 8+ characters and at least one number.");

     jQuery.validator.addMethod("contrpwcheck", function(password, element) {
    return password.match(/^(?=.*\d)(?=.*[.!@?#"$%&:';()*\+,\/;\-=[\\\]\^_{|}<>£€¥•~`])(?=.*[a-z])(?=.*[A-Z]).{6,}$/);
    }, "New password should contains min. 8 characters, one digit, Special characters, Upper case & lower case letter.");

});
// *********************reset password email code end ****************************
//********************************Job Estimation ****************************************//
// function assign_job_addon_ids(){
//   console.log('in method');
//   var chk_box_ids = []
//   $('.job_add_on_chbx').change(function(){
//     console.log(11);
//     $('.show-suggested').hide()
//     $('.bid-price-scroller').hide()
//     // console.log($(this).attr('checked'));
//     if(this.checked){
//       // chk_box_ids.append($(this).data('id'))
//       chk_box_ids.push($(this).val())
//       console.log(12);
//       console.log(chk_box_ids);
//     }
//     $('#job_addon_attr').val(chk_box_ids)
//   })
//   console.log($('#job_addon_attr').val())
// }
// function assign_job_attr_choices(){
//   console.log('in method 2');
//   var chk_box_ids = []
//   $('.job_attribute_choice__chbx').change(function(){
//     // alert(1)
//     console.log(111);
//
//     // console.log($(this).attr('checked'));
//     if(this.checked){
//       // chk_box_ids.append($(this).data('id'))
//       chk_box_ids.push($(this).val())
//       console.log(122);
//       console.log(chk_box_ids);
//     }
//     $('#job_attr_choice').val(chk_box_ids)
//   })
//   console.log($('#job_addon_attr').val())
// }
// function fetch_addon_choices(){
//   $('.job_attribute_choice__chbx').each(function(chk){
//     console.log('----in fecth---');
//     console.log(chk);
//   })
// }
function assign_job_addon_ids(){
  // console.log('in method');
  var chk_box_ids = []
  if($('.job_add_on_chbx:checked').length > 0){
    $('.get_estimated_price').attr('disabled', false)
    $('.get_estimated_price').css('cursor', 'pointer')
    $('.job_add_on_chbx:checked').each(function(){
      chk_box_ids.push($(this).val())
    })
  }
  $('.job_add_on_chbx').change(function(){
    // console.log(11);
    $('.show-suggested').hide()
    $('.bid-price-scroller').hide()
    console.log("assign_job_addon_ids=========="+ $('.delivery_type_on_change_job').val());
    // if ($('.delivery_type_on_change_job').val() != 3 || $('.delivery_type_on_change_job').val() != '3') //###
    // {
    //   $('.create_job_btn').attr('disabled', true)
    //   $('.create_job_btn').css('cursor', 'not-allowed')
    // }
    if ($('.delivery_type_on_change_job').val() != 3 || $('.delivery_type_on_change_job').val() != '3') //###
      {
        // alert(1)
        if ($('.delivery_type_on_change_job').val() == 1 && ($('#delivery_job_service_type').val() == 3 || $('#delivery_job_service_type').val() == '3')) {
          // alert(2)
          $('.create_job_btn').attr('disabled', false)
          $('.create_job_btn').css('cursor', 'pointer')
        }
        else{
          // alert(3)
          $('.create_job_btn').attr('disabled', true)
          $('.create_job_btn').css('cursor', 'not-allowed')
        }
      }
    $('.bid_amt_block').hide()
    // console.log($(this).attr('checked'));
    if(this.checked){
      $('.get_estimated_price').attr('disabled', false)
      $('.get_estimated_price').css('cursor', 'pointer')
      // chk_box_ids.append($(this).data('id'))
      chk_box_ids.push($(this).val())
      // console.log(12);
      // console.log(chk_box_ids);
      if(this.checked == true && $('.job_add_on_chbx:checked').length > 0){
        $('#is_attr_changed').val(true)
        $('.get_estimated_price').attr('disabled', false)
        $('.get_estimated_price').css('cursor', 'pointer')
      }
    }else{
      if(chk_box_ids.includes($(this).val()) && (this.checked == false)){
        chk_box_ids.remove($(this).val())
      }
      if(this.checked == false && $('.job_add_on_chbx:checked').length === 0 && $('.job_attribute_choice__chbx:checked').length === 0){
        $('.get_estimated_price').attr('disabled', true)
        $('.get_estimated_price').css('cursor', 'not-allowed')
      }
    }
    $('#job_addon_attr').val(chk_box_ids)
    $('#estimated_price').val($('#suggested_price').val())
  })
  // console.log($('#job_addon_attr').val())
}
function assign_job_attr_choices(){
  // console.log('in method 2');
  var chk_box_ids = []
  if($('.job_attribute_choice__chbx:checked').length > 0){
    $('.get_estimated_price').attr('disabled', false)
    $('.get_estimated_price').css('cursor', 'pointer')
    $('.job_attribute_choice__chbx:checked').each(function(){
      chk_box_ids.push($(this).val())
    })
  }
  if($('.delivery_type_on_change_job').val() != ''){
    check_service_type()
    setTimeout(function(){
      if ($('.delivery_type_on_change_job').val() != 3 || $('.delivery_type_on_change_job').val() != '3') //###
      {
        if ($('.delivery_type_on_change_job').val() == 1 && ($('#delivery_job_service_type').val() == 3 || $('#delivery_job_service_type').val() == '3')) {
          $('.hourly_price_block').show()
          $('#is_hourly_or_quoted').val(true)
          $('.variable_price_block').hide();
          $('.send_to_queue_block').show();
          $('.quoted_price_block').hide();
          $('.schedule_dates_div').hide();
          $('.create_job_btn').attr('disabled', false)
          $('.create_job_btn').css('cursor', 'pointer')
        }
        else{
          $('#is_hourly_or_quoted').val(false)
          $('.hourly_price_block').hide()
          $('.bid_amt_block').hide()
          $('.variable_price_block').show();
          $('.send_to_queue_block').show();
          $('.quoted_price_block').hide();
          $('.schedule_dates_div').hide();
          $('.create_job_btn').attr('disabled', true)
          $('.create_job_btn').css('cursor', 'not-allowed')
        }
      }
    }, 300)
  }
  $('.job_attribute_choice__chbx').change(function(){
    // alert(1)
    // console.log(111);
    $('#estimate_btn_click').val(false)
    $('.show-suggested').hide()
    $('.bid-price-scroller').hide()
    console.log("assign_job_attr_choices=========="+ $('.delivery_type_on_change_job').val());
    if ($('.delivery_type_on_change_job').val() != 3 || $('.delivery_type_on_change_job').val() != '3') //###
      {
        if ($('.delivery_type_on_change_job').val() == 1 && ($('#delivery_job_service_type').val() == 3 || $('#delivery_job_service_type').val() == '3')) {
          $('.hourly_price_block').show()
          $('#is_hourly_or_quoted').val(true)
          $('.variable_price_block').hide();
          $('.send_to_queue_block').show();
          $('.quoted_price_block').hide();
          $('.schedule_dates_div').hide();
          $('.create_job_btn').attr('disabled', false)
          $('.create_job_btn').css('cursor', 'pointer')
        }
        else{
          // alert(3)
          $('#is_hourly_or_quoted').val(false)
          $('.hourly_price_block').hide()
          $('.bid_amt_block').hide()
          $('.variable_price_block').show();
          $('.send_to_queue_block').show();
          $('.quoted_price_block').hide();
          $('.schedule_dates_div').hide();
          $('.create_job_btn').attr('disabled', true)
          $('.create_job_btn').css('cursor', 'not-allowed')
        }
      }

    $('.bid_amt_block').hide()
    // console.log($(this).attr('checked'));
    if(this.checked){
      // chk_box_ids.append($(this).data('id'))
      $('.get_estimated_price').attr('disabled', false)
      $('.get_estimated_price').css('cursor', 'pointer')
      chk_box_ids.push($(this).val())
      // console.log(122);
      // console.log(chk_box_ids);
      if(this.checked == true && $('.job_attribute_choice__chbx:checked').length > 0){
        $('#is_attr_changed').val(true)
        $('.get_estimated_price').attr('disabled', false)
        $('.get_estimated_price').css('cursor', 'pointer')
      }
    }else{
      // debugger
      if(chk_box_ids.includes($(this).val()) && (this.checked == false)){
        chk_box_ids.remove($(this).val())
      }
      if(this.checked == false && $('.job_add_on_chbx:checked').length === 0 && $('.job_attribute_choice__chbx:checked').length === 0){
        $('.get_estimated_price').attr('disabled', true)
        $('.get_estimated_price').css('cursor', 'not-allowed')
      }
    }
    $('#job_attr_choice').val(chk_box_ids)
    $('#estimated_price').val($('#suggested_price').val())
  })
  // console.log($('#job_addon_attr').val())
}
function estimate_bid_job_price(){
  var bid_price = 0
	$('.get_estimated_price').on('click', function(){
    $('#estimate_btn_click').val(true)
		// console.log(1);
    // if(this.checked){
      // var id = $(this).data(id)
      var property_udid = $('.property_dropdown option:selected').data('udid')
      var job_type_udid = $("#job_job_type_udid").val()
      var delivery_type = $(".delivery_type_on_change_job").val()
      if(!delivery_type){
        delivery_type = 1
      }
      var job_addons = $('#job_addon_attr').val()
      var job_attr_choices = $('#job_attr_choice').val()
      // var selected_price = $('#estimated_price').val()
      var selected_price = $('#estimated_val').val()
      var estimated = $('#suggested_price').val()
      // console.log('=======estimated=======', $('#suggested_price').val());
      var request_data = ''
      if(property_udid && job_type_udid && delivery_type){
        var request_data = {
          property_udid: property_udid,
          job_type_udid: job_type_udid,
          delivery_type: delivery_type,
          add_on_udids: job_addons,
          job_attr_choices: job_attr_choices,
          bid_amnt: selected_price
        }
      }
      // console.log(request_data);
      if(request_data){
        // debugger
        $.ajax({
          url: '/admin/jobs/get_job_estimate',
          method: 'post',
          dataType: 'json',
          data: {request_data: request_data},
          success: function(data){

            // console.log('-----success----');
            // console.log(data);
            // debugger
            // initialize_slider()
            // $(this).addClass('hide')
            // $('.bid-price-scroller').css('display', 'block')
            // $('#rs-range-line').attr('min', parseFloat(data['min_range']).toFixed(2))
            // $('#rs-range-line').attr('max', parseFloat(data['max_range']).toFixed(2))
            // $('.min-value').text('C$ '+parseFloat(data['min_range']).toFixed(2))
            // $('.max-value').text('C$ '+parseFloat(data['max_range']).toFixed(2))
            // $('#rs-range-line').attr('value', parseInt(data['estimated_price']))
            // $('#rs-bullet').text(parseInt(data['estimated_price']))
            console.log("request_data "+data);
            $('.min_range_val').text('Min Range: C$ '+parseFloat(data['min_range']).toFixed(2))
            $('.max_range_val').text('Max Range: C$ '+parseFloat(data['max_range']).toFixed(2))
            $('#estimated_val').attr('min', data['min_range'].toFixed(2))
            $('#estimated_val').attr('max', data['max_range'].toFixed(2))

            if($('#is_attr_changed').val() == true || $('#is_attr_changed').val() == 'true'){
              console.log('-------in true========');
              $('#estimated_price').val(data['estimated_price'].toFixed(2))
              $('#suggested_price').val(data['estimated_price'].toFixed(2))
            }else{
              console.log('-------in false========');
              $('#estimated_price').val(data['selected_price'].toFixed(2))
              $('#suggested_price').val(data['estimated_price'].toFixed(2))
            }
            // $('#suggested_price').val(parseInt(data['selected_price']))
            // console.log('------------------------------');
            // console.log(estimated);
            // console.log($('#estimated_price').val());
            // console.log($('#suggested_price').val());
            // console.log(data['estimated_price']);
            // console.log('------------------------------');
            if(estimated != ""){
              estimated = parseFloat(estimated).toFixed(2)
            }
            if((parseInt(selected_price) == 0) || (estimated != data['estimated_price'].toFixed(2))){
              // console.log('---------------in if------------------', data['estimated_price']);
              $('#estimated_val').val(data['estimated_price'].toFixed(2))
              // addiotional below line
              $('#estimated_price').val(data['estimated_price'].toFixed(2))
              // $('#rs-bullet').text(Math.ceil(data['estimated_price']).toFixed(2))
              // $('#rs-range-line').attr('value', data['estimated_price'].toFixed(2))
            }else{
             //  $('#rs-bullet').text(parseInt(selected_price))
             // $('#rs-range-line').attr('value', parseInt(selected_price))
             // console.log('---------------in else------------------', data['selected_price']);
             if($('#is_attr_changed').val() == true || $('#is_attr_changed').val() == 'true'){
               $('#estimated_val').val(data['estimated_price'].toFixed(2))
               // $('#rs-bullet').text(Math.ceil(data['estimated_price']).toFixed(2))
               // $('#rs-range-line').attr('value', data['estimated_price'].toFixed(2))
             }else{
               $('#estimated_val').val(data['selected_price'].toFixed(2))
             $('#rs-bullet').text(Math.ceil(data['selected_price']).toFixed(2))
             $('#rs-range-line').attr('value', data['selected_price'].toFixed(2))}
             // $('#suggested_price').val(parseInt(data['selected_price']));
           }
           // $('#estimated_val').show()
           $('.bid_amt_block').show()
            /*************************Updated changes commented******************/
            // // if(window.location.href.split('/').includes('edit')){
            // //   if(parseInt(data['selected_price']) == 0 && (parseInt(selected_price) == 0)){
            // //     $('#suggested_price').val(parseInt(data['estimated_price']))
            // //     $('#rs-bullet').text(parseInt(data['estimated_price']))
            // //     $('#rs-range-line').attr('value', parseInt(data['estimated_price']))
            // //   }else{
            // //     $('#suggested_price').val(parseInt(selected_price))
            // //     $('#rs-bullet').text(parseInt(selected_price))
            // //     $('#rs-range-line').attr('value', parseInt(selected_price))
            // //   }
            // // }else{
            // //   $('#rs-bullet').text(parseInt(data['estimated_price']))
            // //   $('#estimated_price').val(parseInt(data['estimated_price']))
            // //   $('#suggested_price').val(data['estimated_price']);
            // //   $('#rs-range-line').attr('value', parseInt(data['estimated_price']))
            // // }
            // console.log('-----------------------------');
            // console.log(parseInt(selected_price));
            // console.log(parseInt($('#suggested_price').val()));
            // console.log('-----------------------------');
            // // if(parseInt(selected_price) == 0){
            // //   $('#rs-bullet').text(parseInt(data['estimated_price']))
            // //   $('#rs-range-line').attr('value', parseInt(data['estimated_price']))
            // // }else{
            // //   $('#rs-bullet').text(parseInt(selected_price))
            // //   $('#rs-range-line').attr('value', parseInt(selected_price))
            // //   // $('#suggested_price').val(parseInt(data['selected_price']));
            // // }



            // initialize_slider()
            $('.create_job_btn').attr('disabled', false)
            $('.create_job_btn').css('cursor', 'pointer')
            // $('.bid-price-scroller').css('visibility', 'visible')
          }
        })
      }
      $("#job_property_udid").val($(".property_dropdown option:selected").data('udid'));
      if($(".job_type_create_job").val() == '' || $(".job_type_create_job").val() == undefined){
        $(".job_type_create_job").val($("#job_type_edit_id").val());
      }

      // console.log(this);
    // }
	})
  // $('.job_add_on_chbx')
}
function initialize_slider()
{
  // var rangeSlider = document.getElementById("rs-range-line");
  // var rangeBullet = document.getElementById("rs-bullet");

  // rangeSlider.addEventListener("input", showSliderValue, false);

  // function showSliderValue() {
  //   // console.log('------slider val---',rangeSlider.value);
  //   rangeBullet.innerHTML = Math.ceil(parseFloat(rangeSlider.value)).toFixed(2);
  //   var bulletPosition = (rangeSlider.value /rangeSlider.max).toFixed(2);
  //   rangeBullet.style.left = (bulletPosition * 578).toFixed(2) + "px";
  //   $('#estimated_price').val(parseFloat(rangeSlider.value).toFixed(2))
  // }
  // $('.show-suggested').show()
  // var suggested = $('#suggested_price').val()
  // if(suggested != '' || suggested != undefined){
  //   // suggested = $('#suggested_price').val()
  //   suggested = parseFloat(suggested).toFixed(2)
  // }
  // $('.estimated-here').html('<b>C$: '+ suggested+'</b><br />')
  // $('#suggested_price').val($('#estimated_price').val());
}

function check_service_type()
{
  if($('#job_job_type_udid').val()){
    $.ajax({
      url: '/admin/jobs/get_service_type',
      method: 'post',
      dataType: 'json',
      data: {job_type_udid: $('#job_job_type_udid').val(), property_udid: $('#job_property_udid').val()},
      success: function(response){
        console.log('--------resp-------', response);
        console.log(response['service_type'])
        console.log('---------------');
        // res = response
        // console.log(res);
        $('#delivery_job_service_type').val(response['service_type'])
        $('#hours_to_complete').attr('max', response['max_hours'])
        $('#hours_to_complete').attr('min', response['min_hours'])
        $('.estimated-hourly-here').text(parseFloat(response['price']))
        $('#is_hourly_or_quoted').val(response['is_hourly_or_quoted'])
        // return response;
      }
    })
  }
  // alert(res)
  // console.log('0----in rsp====', res);

}
